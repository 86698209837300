import { notify } from "react-notify-toast"

export const toastr = (
  message = "An error occured, please try again later",
  type = "error",
  timeout = 4000,
  color = "red"
) => {
  return notify.show(message, type, timeout, color)
}
