//Helper functions

const isEmail = email => {
  const regEx = /[a-z0-9\._%+!$&*=^|~#%'`?{}/\-]+@([a-z0-9\-]+\.){1,}([a-z]{2,16})/
  if (email.match(regEx)) return true
  else return false
}
const isEmpty = string => {
  if (!string) return true
  if (string.trim() === "") return true
  else return false
}

exports.validateContactData = data => {
  let errors = {}

  if (isEmpty(data.email)) {
    errors.email = "Email must not be empty"
  } else if (!isEmail(data.email)) {
    errors.email = "Email be a valid email address"
  }

  if (isEmpty(data.name)) errors.name = "Name must not be empty"
  if (isEmpty(data.title)) errors.title = "Title must not be empty"
  if (isEmpty(data.message)) errors.message = "Message must not be empty"

  return {
    errors,
    valid: Object.keys(errors).length === 0 ? true : false,
  }
}

exports.validateSubscribeData = data => {
  let errors = {}

  if (isEmpty(data.email)) {
    errors.email = "Email must not be empty"
  } else if (!isEmail(data.email)) {
    errors.email = "Email be a valid email address"
  }

  return {
    errors,
    valid: Object.keys(errors).length === 0 ? true : false,
  }
}
