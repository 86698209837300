import React from "react"
import Img from "gatsby-image"
import Layout from "components/layout"
import Footer from "components/footer"
import SEO from "components/seo"
import { graphql } from "gatsby"
import { validateContactData, validateSubscribeData } from "utils/validator"
import { toastr } from "utils/helper"
export const query = graphql`
  query {
    allFile(
      filter: { sourceInstanceName: { eq: "content" }, name: { eq: "contact" } }
    ) {
      edges {
        node {
          childMarkdownRemark {
            frontmatter {
              lhp
              lhs
              intro
              address
              phone
              email
              newsletter
              newsletter_text
              newsletter_button_text
            }
          }
        }
      }
    }
    placeholderImage: file(relativePath: { eq: "side-image.png" }) {
      childImageSharp {
        fixed(width: 300, quality: 100) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`
class Contact extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showMenu: false,
      show: false,
      slide: false,
      slide_shell: false,
      active: props.active,
      contact: {
        status: true,
        message: "Send ",
      },
      newsletter: {
        status: true,
        message: "Loading ... ",
      },
    }
  }
  Image(data) {
    return (
      <Img
        fixed={data.placeholderImage.childImageSharp.fixed}
        alt="Nwachukwu Chibuike"
        className="bio-image"
      />
    )
  }

  encode(data) {
    return Object.keys(data)
      .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
      .join("&")
  }
  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value })
  }
  resetForm(data) {
    data.forEach(function(e) {
      e.value = ""
      this.setState({
        [e.name]: "",
      })
    }, this)
  }
  showLoader(val) {
    if (val === "contact") {
      this.setState({
        contact: {
          status: false,
          message: "Loading ... ",
        },
      })
    } else if (val === "newsletter") {
      this.setState({
        newsletter: {
          status: false,
          message: "Loading ... ",
        },
      })
    }
  }
  hideLoader(val = "contact") {
    if (val === "contact") {
      this.setState({
        contact: {
          status: true,
          message: "Send",
        },
      })
    } else if (val === "newsletter") {
      const data = this.props.data.allFile.edges[0].node.childMarkdownRemark
        .frontmatter

      this.setState({
        newsletter: {
          status: true,
          message: data.newsletter_button_text,
        },
      })
    }
  }
  handleSubmit = e => {
    e.preventDefault()
    let check = validateContactData({
      name: this.state.name,
      email: this.state.email,
      title: this.state.title,
      message: this.state.message,
    })

    if (check.valid === false) {
      if (check.errors.name) {
        e.target.name.focus()
        return toastr(check.errors.name, "error")
      }
      if (check.errors.email) {
        e.target.email.focus()
        return toastr(check.errors.email, "error")
      }
      if (check.errors.title) {
        e.target.title.focus()
        return toastr(check.errors.title, "error")
      }
      if (check.errors.message) {
        e.target.message.focus()
        return toastr(check.errors.message, "error")
      }
    }
    this.showLoader("contact")
    const form = e.target

    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: this.encode({
        "form-name": form.getAttribute("name"),
        email: this.state.email,
        name: this.state.name,
        title: this.state.title,
        message: this.state.message,
      }),
    })
      .then(() => {
        this.toggleMessage()
        this.resetForm([form.name, form.email, form.title, form.message])
        this.hideLoader("contact")
        return toastr(
          "Thank you for contacting me! I will get back as soon as possible.",
          "success",
          8000,
          "green"
        )
      })
      .catch(error => toastr(error, "error"))
  }

  handleSubscribe = e => {
    e.preventDefault()
    let check = validateSubscribeData({ email: this.state.news_email })
    if (check.valid === false) {
      if (check.errors.email) {
        e.target.news_email.focus()
        return toastr(check.errors.news_email, "Error")
      }
    }
    this.showLoader("newsletter")

    const form = e.target
    return fetch("/.netlify/functions/subscribe", {
      method: "POST",
      headers: {
        accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email: this.state.news_email }),
    })
      .then(response => {
        if (response.status == 200) {
          this.hideLoader("newsletter")
          this.resetForm([form.news_email])
          this.togglePopUp()
          return toastr("Thank you for subscribing!", "success", 9000, "green")
        } else if (response.status == 422) {
          this.hideLoader("newsletter")
          this.resetForm([form.news_email])
          return toastr(
            "The email address provided is already subscribed",
            "warning",
            8000,
            "yellow"
          )
        } else {
        }
      })
      .catch(error => {
        this.hideLoader("newsletter")
        this.resetForm([form.news_email])
        toastr("An error occured", "error")
      })
  }

  toggleMessage() {
    this.setState({
      slide: true,
      slide_shell: true,
    })

    setTimeout(() => {
      this.setState({
        slide: false,
        slide_shell: false,
      })
    }, 1000)
  }
  togglePopUp() {
    this.setState({
      show: true,
    })
  }

  render() {
    const data = this.props.data.allFile.edges[0].node.childMarkdownRemark
      .frontmatter

    return (
      <div className="body">
        <Layout link="contact">
          <SEO
            title="Contact Me"
            description="Hi, I am nwachukwu chibuike, you can use this page to reach out to me. I would be glad you did!"
            keywords="Nwachukwu chibuike, Chibuike, Nwachukwu, Web developer lagos, nigeria, Contact Nwachukwu chibuike"
          />

          <main id="contact">
            <h1 className="lg-heading ">
              {data.lhp}&nbsp;
              <span className="text-secondary ">{data.lhs}</span>
            </h1>
            <h2 className="sm-heading ">{data.intro}</h2>
            <span className="contact-icons icons">
              <a href="#">
                <i className="fa fa-globe fa-2x"> </i> {data.address}
              </a>
              <a href={`tel:${data.phone}`}>
                {" "}
                <i className="fa fa-phone fa-2x"></i> {data.phone}
              </a>
              <a href={`mailto:${data.email}`}>
                {" "}
                <i className="fa fa-inbox fa-2x"> </i> {data.email}
              </a>
            </span>
            <div className="contact">
              <section>
                <div>
                  <form
                    name="contact"
                    method="POST"
                    data-netlify="true"
                    netlify-honeypot="bot-field"
                    onSubmit={this.handleSubmit}
                  >
                    <input type="hidden" name="form-name" value="contact" />
                    <div hidden>
                      <label>
                        Don’t fill this out:{" "}
                        <input name="bot-field" onChange={this.handleChange} />
                      </label>
                    </div>
                    <input
                      type="text"
                      name="name"
                      placeholder="Your Name"
                      onChange={this.handleChange}
                    />{" "}
                    <br />
                    <input
                      type="email"
                      name="email"
                      placeholder="Your Email"
                      onChange={this.handleChange}
                    />
                    <br />
                    <input
                      type="subject"
                      name="title"
                      placeholder="Title"
                      onChange={this.handleChange}
                    />
                    <br />
                    <textarea
                      name="message"
                      placeholder="Your message"
                      onChange={this.handleChange}
                    ></textarea>
                    <br />
                    <input
                      id="contact-submit"
                      type="submit"
                      disabled={!this.state.contact.status}
                      value={this.state.contact.message}
                    />
                  </form>
                </div>
              </section>

              <aside className="contact-image">
                {this.Image(this.props.data)}
              </aside>
            </div>
            <div className="icons">
              <a href="#" target="_blank">
                <i className="fa fa-twitter fa-2x"></i>
              </a>
              <a href="#" target="_blank">
                <i className="fa fa-facebook fa-2x"></i>
              </a>
              <a
                href="https://linkedin.com/in/chibuike-nwachukwu-29a7a0111"
                target="_blank"
              >
                <i className="fa fa-linkedin fa-2x"></i>
              </a>
              <a href="https://github.com/chyke007" target="_blank">
                <i className="fa fa-github fa-2x"></i>
              </a>
            </div>
          </main>
          <aside>
            <div
              id="pop-up"
              className={this.state.show ? "pop-up swing-back" : "pop-up"}
            >
              <div className="container">
                <h1>{data.newsletter}</h1>
                <p>{data.newsletter_text}</p>
                <form
                  name="newsletter"
                  method="POST"
                  onSubmit={this.handleSubscribe}
                >
                  <input
                    type="email"
                    name="news_email"
                    placeholder="Your Email"
                    onChange={this.handleChange}
                    required
                  />
                  <button
                    type="submit"
                    disabled={!this.state.newsletter.status}
                  >
                    {this.state.newsletter.status
                      ? data.newsletter_button_text
                      : this.state.newsletter.message}{" "}
                  </button>
                  <div className="cross" onClick={() => this.togglePopUp()}>
                    x
                  </div>
                </form>
              </div>
            </div>
          </aside>
          {this.state.slide_shell ? (
            <aside
              className={
                this.state.slide ? "trash-offline trash" : "trash-offline"
              }
            >
              <a href="#">
                <i className="fa fa-inbox fa-2x"></i>
              </a>
            </aside>
          ) : (
            ""
          )}
        </Layout>
        <Footer />
      </div>
    )
  }
}

export default Contact
